<template>
  <div id="healthcare">
    <v-carousel
      interval="4000"
      hide-delimiters
      :show-arrows="false"
      height="550"
    >
      <div class="container_">
        <v-carousel-item
          src="../../assets/images/health-bg 1 (1).png"
          class="image"
        >
          <v-row class="fill-height" align="center" justify="center">
            <div class="overlay">
              <v-col
                :style="
                  $vuetify.breakpoint.xs
                    ? 'padding:0 3rem'
                    : 'padding:0 5rem 6rem'
                "
              >
                <p class="white--text mb-8 head">
                  Healthcare
                </p>
                <h1 class="font-weight-bold white--text">
                  A comprehensive condition <br />monitoring solution for <br />
                  healthcare industry
                </h1>
              </v-col>
              <!-- <h2>About Us</h2> -->
            </div>
          </v-row>
        </v-carousel-item>
      </div>
    </v-carousel>
    <div>
      <v-col class="d-flex justify-center pt-12">
        <h4 class="text--center">
          According to the World Health Organization, 19.4 million infants
          globally do not have access to life saving vaccines largely due to a
          lack of inadequate cold chain equipment.
        </h4>
      </v-col>
      <v-row
        :style="
          $vuetify.breakpoint.xs
            ? 'overflow: hidden'
            : 'padding: 4rem 0 4rem 4rem;overflow: hidden;'
        "
      >
        <v-col cols="12" sm="6" class="pa-8">
          <h2 class="pb-8">
            Preserving the integrity of <br />
            healthcare commodities.
          </h2>
          <p class="">
            Most pharmaceutical products like ( vaccines, diluents, etc.) are
            temperature sensitive. For them to remain potent, they require
            special handling and must be kept within the cold chain at the
            manufacturer's recommended temperature from the moment they are
            manufactured to when they are stored, transported, and distributed
            to the final consumer. <br /><br />
            Therefore, it is compulsory to maintain the cold chain using a
            network of cold stores, freezers, and other refrigerated systems,
            and also monitor the condition of pharmaceuticals during their
            storage, transport, and distribution, in real-time.<br /><br />
            With real-time cold chain monitoring, reports and actionable data
            are generated immediately a temperature excursion is detected so
            designated persons at each level of the cold chain can take
            proactive decisions that reduce damages or loss of integrity.<br /><br />
            Our Internet-of-Things (IoT) approach includes complete online
            information about all of your valuable pharmaceutical products. This
            information can be visualized through our web applications. Gricd
            tracking capabilities provide our customers with safety and security
            anytime and everywhere, freeing them from the worry of the
            unexpected.
          </p>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          :class="$vuetify.breakpoint.xs ? 'pl-8' : 'pl-16'"
          class="dashboard"
          ><img src="../../assets/images/Rectangle 1185@3x.png" alt=""
        /></v-col>
      </v-row>
      <!-- <div v-if="$vuetify.breakpoint.smAndUp">
        <img width="100%" src="../../assets/images/Group 1432.png" alt="" />
      </div> -->

      <!-- apparatus equipment section -->
      <div class="py-12" style="background: rgba(242, 245, 247, 0.46);">
        <div class="top-apparatus text-center">
          <h6>The Problem</h6>
          <h2 class="py-6 px-8">Cooling challenge for healthcare</h2>
        </div>

        <v-row class="px-12 py-5" style="justify-content:space-evenly">
          <div class="apparatus-card mt-10" v-for="(m, i) in icon" :key="i">
            <img
              :src="m.img"
              style="height: 4rem;max-width: 4rem;"
              alt=""
              class="mb-4"
            />
            <p class="p1">{{ m.p1 }}</p>
            <p class="p2">{{ m.p2 }}</p>
            <p class=" text-center">
              {{ m.content }}
            </p>
          </div>
        </v-row>
      </div>

      <v-row
        :style="$vuetify.breakpoint.xs ? 'padding:4rem 1rem' : 'padding:4rem'"
      >
        <v-col cols="12" sm="6" class="prod-container">
          <h2>Why Use Gricd Cold Boxes?</h2>
          <p>
            IoT enabled 15 liter active cooling box to maintain stable internal
            temperature of up to -20° C for up to 24 hours.
          </p>
          <img src="../../assets/images/mote-50 1.png" alt="" width="65%" />
        </v-col>
        <v-col cols="12" sm="6" class="right_">
          <img src="../../assets/images/Group 1402.png" alt="" />
          <div>
            <div class="right-div">
              <h5>Monitor Remotely</h5>
              <p>
                Gricd boxes can be controlled and monitored in real-time,
                allowing health organizations take pro active decisions that
                reduce medication wastage.
              </p>
            </div>
            <div class="right-div">
              <h5>Get ahead of competitors</h5>
              <p class="btm-p">
                Access to new markets and market share. There is a large cold
                chain market that needs to be reached. You can breach that gap
                with our cold boxes.
              </p>
            </div>
            <div class="right-div">
              <h5>Trackable system</h5>
              <p>
                Ability to move premium commodity. We offer an IoT enabled,
                efficient and trackable system of conveying items that are
                dependent on cooling.
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ic1 from '../../assets/about icon/Group 1422 (1).png';
import ic2 from '../../assets/about icon/Group 1421.png';
import ic3 from '../../assets/about icon/Group 1421 (2).png';

export default {
  data: () => ({
    icon: [
      {
        img: ic1,
        p1: 'Potential Harm',
        // p2: 'Plasma Sample',
        content:
          'Damaged medication may cause potential harm to the people who depend on them. Gricd cold boxes effectively preserve vaccines and medication.',
      },
      {
        img: ic2,
        p1: 'Wasted Medication',
        // p2: 'Blood Sample',
        content:
          'Inability to keep medication within required temperatures lead to waste of life-saving medication, and  this negatively impacts revenue and sometimes leads to loss of life.',
      },
      {
        img: ic3,
        p1: 'Injured Reputation',
        // p2: 'Vaccine vials',
        content:
          'Vaccines that reach the last mile in damaged condition can potentially injure the manufacturers reputations.',
      },
    ],
  }),
  metaInfo() {
    return {
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: 'Home',
                item: 'https://gricd.com/',
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: 'Cold Chain Application',
                item: 'https://gricd.com/cold-chain-application/healthcare',
              },
            ],
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#healthcare {
  .img-bcgrd {
    height: 50rem;
    width: 100%;
  }
  background: #fdfeff;
  h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 180%;
    text-align: center;
    color: #c69d6d;
    width: 50%;
    @media (max-width: 1024px) {
      width: 100%;
      text-align: left;
    }
  }
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 0.9rem;
    color: #141414;
  }
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 140%;
    color: #212529;
    @media (max-width: 1024px) {
      // font-size: 30px;
    }
  }
  .right_ {
    display: flex;
    column-gap: 2rem;
    padding: 0 5rem;
    img {
      height: 19rem;
      width: 3rem;
    }
    @media (max-width: 1024px) {
      padding: 0 1rem;
      column-gap: 1rem;
      img {
        height: 19.5rem;
      }
    }
  }
  h5 {
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    padding-bottom: 1rem;
    color: #212529;
  }
  .right-div p {
    margin-bottom: 24%;
    @media (max-width: 1024px) {
      margin-bottom: 7%;
    }
  }
  .prod-container {
    padding: 0.7rem 9rem 0.7rem 4rem;
    h2 {
      padding-bottom: 2rem;
    }
    p {
      padding-bottom: 3rem;
    }
    @media (max-width: 1024px) {
      padding: 0.7rem 1rem;
      p {
        padding-bottom: 1rem;
      }
    }
  }
  .btm-p {
    margin-bottom: 19% !important;
    @media (max-width: 1024px) {
      margin-bottom: 3.8rem !important;
    }
  }
  .small-p {
    @media (max-width: 1024px) {
      font-size: 12px;
    }
  }
  .dashboard {
    display: flex;
    flex-flow: row;
    align-items: center;
    img {
      height: 32rem;
      background: #ffffff;
      box-shadow: 2px 2px 0px rgba(255, 255, 255, 0.2),
        -2px -2px 0px rgba(255, 255, 255, 0.6);
      border-radius: 30px 0 0 30px;
      border: solid 5px #edf3ff;
    }
  }

  p.head {
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    letter-spacing: 1.1px;
    color: #141414;
  }

  @media (max-width: 1290px) and (min-width: 770px) {
    .right-div p {
      margin-bottom: 1.3rem;
    }

    .prod-container {
      padding: 0.7rem 3rem 0.7rem 3rem;
    }
    .btm-p {
      margin-bottom: 3.6rem !important;
    }
  }
  h1 {
    font-size: 2.4rem;
  }
  p.head {
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    letter-spacing: 1.1px;
    color: #141414;
  }

  .container_ {
    position: relative;
  }

  .overlay {
    position: absolute;
    bottom: 0rem;
    background: black;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    transition: 0.5s ease;
    opacity: 1;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    h2 {
      color: white;
    }
  }

  .container_ .overlay {
    opacity: 1;
  }

  .apparatus-card {
    width: 18rem;
    // border: solid;
    height: 15rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  h6 {
    font-size: 1rem;
    color: #28a84a;
    font-weight: 500;
  }
  .p1 {
    font-size: 1rem;
    font-weight: 500;
  }
  .p2 {
    font-size: 1rem;
    font-weight: 500;
    color: #28a84a;
  }
}
</style>
